import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import styles from '../../styles/animations/bnc-fraude/castonguay.module.css';

import portraitImg from '../../assets/img/animations/bnc-fraude/mathieu-castonguay.jpg';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function BncFraudeCastonguay({ className }) {
    const { ref, visible = false } = useIsVisible({ rootMargin: '-40%', persist: true });

    const [introComplete, setIntroComplete] = useState(false);
    const onAnimationEnd = useCallback(() => {
        setIntroComplete(true);
    }, [setIntroComplete]);

    return (
        <div
            ref={ref}
            className={classNames(styles.container, className, {
                [styles.intro]: visible && !introComplete,
                [styles.afterIntro]: visible && introComplete,
            })}
        >
            <svg
                width="430"
                height="433"
                viewBox="0 0 430 433"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    className={styles.picture}
                    filter="url(#filter0_d_39_179)"
                    onAnimationEnd={onAnimationEnd}
                >
                    <rect
                        x="64"
                        y="88.7273"
                        width="246"
                        height="324"
                        rx="30"
                        transform="rotate(-5.0671 64 88.7273)"
                        fill="url(#pattern0_39_179)"
                    />
                    <rect
                        x="66.711"
                        y="90.9967"
                        width="241"
                        height="319"
                        rx="27.5"
                        transform="rotate(-5.0671 66.711 90.9967)"
                        stroke="#5E79FF"
                        strokeWidth="5"
                    />
                </g>
                <g className={styles.computer} clipPath="url(#clip0_39_179)">
                    <path
                        d="M377.757 53.6956L264.428 18.3185C261.371 17.3644 258.12 19.0693 257.166 22.1264L232.001 102.742C231.046 105.799 232.75 109.051 235.806 110.005L349.136 145.382C352.192 146.336 355.443 144.631 356.397 141.574L381.562 60.9583C382.517 57.9013 380.813 54.6497 377.757 53.6956Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M377.757 53.6956L264.428 18.3185C261.371 17.3644 258.12 19.0693 257.166 22.1264L232.001 102.742C231.046 105.799 232.75 109.051 235.806 110.005L349.136 145.382C352.192 146.336 355.443 144.631 356.397 141.574L381.562 60.9583C382.517 57.9013 380.813 54.6497 377.757 53.6956Z"
                        fill="url(#paint0_linear_39_179)"
                    />
                    <path
                        d="M348.994 147.549L234.691 111.868C230.795 110.652 228.614 106.49 229.831 102.593L255.298 21.0088C256.515 17.1119 260.676 14.93 264.571 16.1461L378.874 51.8273C382.77 53.0434 384.951 57.2049 383.734 61.1017L358.265 142.692C357.048 146.589 352.887 148.771 348.992 147.555L348.994 147.549ZM263.312 20.1813C261.641 19.6596 259.861 20.5926 259.34 22.2642L233.87 103.854C233.348 105.526 234.281 107.305 235.952 107.827L350.255 143.508C351.926 144.03 353.705 143.097 354.227 141.425L379.695 59.8407C380.217 58.1691 379.284 56.3897 377.613 55.868L263.31 20.1868L263.312 20.1813Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M363.223 144.647L224.639 101.386C221.583 100.432 218.331 102.137 217.377 105.194L209.026 131.946C208.072 135.003 209.776 138.254 212.832 139.209L351.417 182.47C354.473 183.424 357.724 181.719 358.678 178.662L367.029 151.91C367.983 148.853 366.28 145.601 363.223 144.647Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M363.223 144.647L224.639 101.386C221.583 100.432 218.331 102.137 217.377 105.194L209.026 131.946C208.072 135.003 209.776 138.254 212.832 139.209L351.417 182.47C354.473 183.424 357.724 181.719 358.678 178.662L367.029 151.91C367.983 148.853 366.28 145.601 363.223 144.647Z"
                        fill="url(#paint1_linear_39_179)"
                    />
                    <path
                        d="M351.275 184.636L211.722 141.073C207.826 139.857 205.645 135.695 206.862 131.799L215.517 104.072C216.733 100.175 220.894 97.9935 224.79 99.2096L364.348 142.775C368.244 143.991 370.425 148.152 369.208 152.049L360.553 179.775C359.337 183.672 355.176 185.854 351.28 184.638L351.275 184.636ZM223.528 103.25C221.857 102.729 220.078 103.662 219.556 105.333L210.901 133.06C210.379 134.731 211.312 136.511 212.983 137.032L352.541 180.597C354.213 181.119 355.992 180.186 356.514 178.514L365.169 150.788C365.691 149.116 364.758 147.337 363.087 146.815L223.528 103.25Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M358.485 64.6161L274.07 38.2646C272.542 37.7876 270.916 38.64 270.439 40.1685L253.184 95.444C252.707 96.9725 253.559 98.5983 255.087 99.0753L339.503 125.427C341.031 125.904 342.656 125.051 343.133 123.523L360.388 68.2474C360.865 66.7189 360.013 65.0931 358.485 64.6161Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M358.485 64.6161L274.07 38.2646C272.542 37.7876 270.916 38.64 270.439 40.1685L253.184 95.444C252.707 96.9725 253.559 98.5983 255.087 99.0753L339.503 125.427C341.031 125.904 342.656 125.051 343.133 123.523L360.388 68.2474C360.865 66.7189 360.013 65.0931 358.485 64.6161Z"
                        fill="url(#pattern1_39_179)"
                        style={{ mixBlendMode: 'soft-light' }}
                    />
                    <g clipPath="url(#clip1_39_179)">
                        <path
                            d="M330.414 90.6173C326.267 103.901 312.133 111.31 298.85 107.163C285.566 103.017 278.156 88.8871 282.304 75.5991C286.452 62.311 300.584 54.9064 313.868 59.0531C327.152 63.1998 334.561 77.3336 330.414 90.6173Z"
                            fill="#F04E3E"
                        />
                        <path
                            d="M298.47 108.378C284.538 104.029 276.744 89.1535 281.093 75.2212C285.442 61.2889 300.313 53.4932 314.246 57.8424C328.178 62.1916 335.974 77.063 331.625 90.9953C327.275 104.928 312.404 112.723 298.472 108.374L298.47 108.378ZM313.49 60.2639C300.894 56.3318 287.446 63.3809 283.514 75.9771C279.582 88.5733 286.631 102.021 299.228 105.953C311.824 109.885 325.271 102.836 329.203 90.2394C333.135 77.6433 326.086 64.196 313.49 60.2639Z"
                            fill="#DEE4FF"
                        />
                        <path
                            d="M301.418 85.3293L304.498 71.907L306.15 66.6143L315.918 69.6635L314.211 75.1336L309.21 87.7617L301.418 85.3293ZM297.256 96.7006L299.936 88.1172L308.709 90.856L306.03 99.4394L297.256 96.7006Z"
                            fill="#DEE4FF"
                        />
                    </g>
                    <path
                        d="M339.114 127.529L254.205 101.024C251.704 100.243 250.298 97.5683 251.081 95.0608L268.49 39.2928C269.271 36.7908 271.945 35.384 274.451 36.1665L359.354 62.67C361.861 63.4525 363.261 66.1252 362.479 68.6327L345.07 124.401C344.289 126.903 341.615 128.31 339.108 127.527L339.114 127.529ZM273.19 40.2073C272.913 40.1209 272.615 40.277 272.529 40.5538L255.12 96.3218C255.034 96.5986 255.19 96.8964 255.467 96.9827L340.369 123.486C340.646 123.573 340.944 123.416 341.03 123.14L358.439 67.3717C358.525 67.0949 358.369 66.7971 358.093 66.7108L273.19 40.2073Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M251.721 123.417L227.871 115.972C226.759 115.625 226.135 114.434 226.482 113.321C226.829 112.208 228.02 111.584 229.132 111.931L252.982 119.376C254.095 119.723 254.721 120.909 254.371 122.027C254.022 123.145 252.839 123.766 251.721 123.417Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M348.257 170.938L329.083 164.952C327.971 164.605 327.345 163.42 327.694 162.301L331.198 151.076C331.546 149.963 332.731 149.337 333.849 149.686L353.023 155.671C354.135 156.019 354.761 157.204 354.412 158.322L350.908 169.548C350.56 170.66 349.375 171.287 348.257 170.938ZM332.364 161.542L347.499 166.266L349.743 159.076L334.609 154.352L332.364 161.542Z"
                        fill="#5E79FF"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_d_39_179"
                        x="64"
                        y="67"
                        width="277.655"
                        height="348.461"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feOffset dx="4" dy="4" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.266667 0 0 0 0 0.333333 0 0 0 0 0.666667 0 0 0 1 0"
                        />
                        <feBlend mode="normal" in="SourceGraphic" />
                    </filter>
                    <pattern
                        id="pattern0_39_179"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                    >
                        <image
                            id="image0_39_179"
                            width="4096"
                            height="2731"
                            href={portraitImg}
                            transform="matrix(0.000482268 0 0 0.000366166 -0.487684 0)"
                        />
                    </pattern>
                    <pattern
                        id="pattern1_39_179"
                        patternContentUnits="objectBoundingBox"
                        width="4.24492"
                        height="6.27897"
                    >
                        <use xlinkHref="#image1_39_179" transform="scale(0.0106123 0.0156974)" />
                    </pattern>
                    <linearGradient
                        id="paint0_linear_39_179"
                        x1="321.092"
                        y1="36.007"
                        x2="292.471"
                        y2="127.694"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F04E3E" />
                        <stop offset="1" stopColor="#FED0D4" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_39_179"
                        x1="293.931"
                        y1="123.016"
                        x2="282.124"
                        y2="160.839"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F04E3E" />
                        <stop offset="1" stopColor="#FED0D4" />
                    </linearGradient>
                    <clipPath id="clip0_39_179">
                        <rect
                            width="161"
                            height="135"
                            fill="white"
                            transform="translate(244.883 10) rotate(17.3364)"
                        />
                    </clipPath>
                    <clipPath id="clip1_39_179">
                        <rect
                            width="53"
                            height="53"
                            fill="white"
                            transform="translate(288.98 49.9553) rotate(17.3364)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

BncFraudeCastonguay.propTypes = propTypes;
BncFraudeCastonguay.defaultProps = defaultProps;

export default BncFraudeCastonguay;
