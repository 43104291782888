import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import React from 'react';

import * as Components from './components';

const propTypes = {
    kind: PropTypes.string,
};

const defaultProps = {
    kind: null,
};

function BncFraude({ kind = null, ...otherProps }) {
    const Component = getComponentFromName(Components, kind, null);
    return Component !== null ? <Component {...otherProps}></Component> : null;
}

BncFraude.propTypes = propTypes;
BncFraude.defaultProps = defaultProps;

export default BncFraude;
