import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import styles from '../../styles/animations/bnc-fraude/factoid.module.css';

const propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    color: PropTypes.string,
    appearFrom: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    body: null,
    color: null,
    appearFrom: null,
    className: null,
};

function BncFraudeFactoid({ title, body, color, appearFrom = null, className }) {
    const { ref, visible = false } = useIsVisible({ rootMargin: '-20%', persist: true });

    const uppercaseAppearFrom =
        appearFrom !== null ? appearFrom.charAt(0).toUpperCase() + appearFrom.slice(1) : null;
    return (
        <div ref={ref}>
            <div
                className={classNames(styles.container, className, {
                    [styles[color]]: styles[color] !== undefined,
                    [styles[`appearFrom${uppercaseAppearFrom}`]]: styles[appearFrom] !== null,
                    [styles.visible]: visible,
                })}
            >
                <h4>{title}</h4>
                <p>{body}</p>
            </div>
        </div>
    );
}

BncFraudeFactoid.propTypes = propTypes;
BncFraudeFactoid.defaultProps = defaultProps;

export default BncFraudeFactoid;
