import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import styles from '../../styles/animations/bnc-fraude/huang.module.css';

import portraitImg from '../../assets/img/animations/bnc-fraude/danny-huang.jpg';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function BncFraudeHuang({ className }) {
    const { ref, visible = false } = useIsVisible({ rootMargin: '-40%', persist: true });

    const [introComplete, setIntroComplete] = useState(false);
    const onAnimationEnd = useCallback(() => {
        setIntroComplete(true);
    }, [setIntroComplete]);

    return (
        <div
            ref={ref}
            className={classNames(styles.container, className, {
                [styles.intro]: visible && !introComplete,
                [styles.afterIntro]: visible && introComplete,
            })}
        >
            <svg
                width="430"
                height="433"
                viewBox="0 0 430 433"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    className={styles.picture}
                    filter="url(#filter0_d_40_430)"
                    onAnimationEnd={onAnimationEnd}
                >
                    <rect
                        x="156.995"
                        y="12"
                        width="246"
                        height="324"
                        rx="30"
                        transform="rotate(5.84498 156.995 12)"
                        fill="url(#pattern0_40_430)"
                    />
                    <rect
                        x="159.228"
                        y="14.7416"
                        width="241"
                        height="319"
                        rx="27.5"
                        transform="rotate(5.84498 159.228 14.7416)"
                        stroke="#5E79FF"
                        stroke-width="5"
                    />
                </g>
                <g className={styles.envelope} clip-path="url(#clip0_40_430)">
                    <path
                        d="M102.974 244.848L55.8507 299.855C55.0132 300.834 54.6747 302.137 54.9365 303.394L70.7769 379.453C71.2447 381.699 73.458 383.136 75.7241 382.664L190.531 358.754C192.797 358.282 194.253 356.081 193.785 353.835L177.944 277.776C177.684 276.523 176.852 275.459 175.693 274.896L110.528 243.275C107.951 242.026 104.839 242.674 102.974 244.848Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M102.974 244.848L55.8507 299.855C55.0132 300.834 54.6747 302.137 54.9365 303.394L70.7769 379.453C71.2447 381.699 73.458 383.136 75.7241 382.664L190.531 358.754C192.797 358.282 194.253 356.081 193.785 353.835L177.944 277.776C177.684 276.523 176.852 275.459 175.693 274.896L110.528 243.275C107.951 242.026 104.839 242.674 102.974 244.848Z"
                        fill="url(#paint0_linear_40_430)"
                    />
                    <path
                        d="M190.87 360.381L76.0629 384.291C72.8975 384.95 69.7893 382.933 69.1357 379.795L53.2953 303.736C52.9277 301.97 53.3909 300.162 54.5771 298.785L101.7 243.774C104.062 241.017 108 240.192 111.268 241.781L176.432 273.398C178.066 274.192 179.212 275.661 179.58 277.43L195.421 353.494C196.075 356.632 194.031 359.723 190.865 360.382L190.87 360.381ZM106.823 244.405C105.847 244.608 104.932 245.124 104.249 245.922L57.1207 300.93C56.6148 301.519 56.4153 302.295 56.5722 303.049L72.4135 379.112C72.6938 380.458 74.0228 381.321 75.3807 381.038L190.188 357.128C191.545 356.845 192.42 355.523 192.139 354.177L176.299 278.118C176.141 277.361 175.649 276.733 174.949 276.39L109.789 244.773C108.839 244.31 107.798 244.202 106.823 244.405Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M70.319 273.885L152.056 256.862C153.189 256.626 154.296 257.344 154.53 258.468L169.326 329.512C169.56 330.635 168.832 331.736 167.699 331.971L85.9616 348.994C84.8286 349.23 83.7219 348.512 83.488 347.389L68.692 276.345C68.4581 275.221 69.1859 274.121 70.319 273.885Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M70.319 273.885L152.056 256.862C153.189 256.626 154.296 257.344 154.53 258.468L169.326 329.512C169.56 330.635 168.832 331.736 167.699 331.971L85.9616 348.994C84.8286 349.23 83.7219 348.512 83.488 347.389L68.692 276.345C68.4581 275.221 69.1859 274.121 70.319 273.885Z"
                        fill="url(#pattern1_40_430)"
                        style={{ mixBlendMode: 'soft-light' }}
                    />
                    <path
                        d="M168.037 333.599L86.3005 350.621C84.2682 351.045 82.2674 349.75 81.8469 347.731L67.0509 276.686C66.6313 274.672 67.9433 272.682 69.9801 272.258L151.717 255.235C153.749 254.812 155.75 256.107 156.171 258.126L170.968 329.175C171.387 331.189 170.075 333.179 168.038 333.603L168.037 333.599ZM70.6569 275.508C70.4321 275.554 70.2858 275.776 70.3322 275.998L85.1291 347.047C85.1756 347.27 85.398 347.414 85.6228 347.367L167.36 330.345C167.585 330.298 167.731 330.077 167.684 329.854L152.888 258.81C152.842 258.587 152.62 258.442 152.395 258.489L70.6578 275.512L70.6569 275.508Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M74.7998 380.727L122.829 331.87C123.866 330.813 123.499 329.052 122.127 328.5L57.132 302.29C56.2004 301.917 55.224 302.725 55.4273 303.701L71.2556 379.702C71.5945 381.329 73.6304 381.914 74.8034 380.721L74.7998 380.727Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M74.7998 380.727L122.829 331.87C123.866 330.813 123.499 329.052 122.127 328.5L57.132 302.29C56.2004 301.917 55.224 302.725 55.4273 303.701L71.2556 379.702C71.5945 381.329 73.6304 381.914 74.8034 380.721L74.7998 380.727Z"
                        fill="url(#paint1_linear_40_430)"
                    />
                    <path
                        d="M74.0681 382.935C73.4746 383.058 72.8514 383.039 72.2434 382.868C70.9057 382.482 69.8977 381.403 69.6155 380.048L53.7862 304.043C53.5671 302.991 53.9511 301.906 54.7833 301.217C55.6155 300.527 56.7561 300.35 57.7644 300.754L122.758 326.955C123.921 327.425 124.763 328.43 125.018 329.652C125.272 330.873 124.901 332.132 124.022 333.026L76.0019 381.881C75.4633 382.425 74.792 382.784 74.0681 382.935ZM57.2281 304.126L72.8977 379.365C72.9395 379.565 73.0787 379.648 73.1923 379.675C73.3068 379.707 73.4681 379.716 73.6112 379.569L121.636 330.714L121.496 330.041L57.2281 304.126Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M190.563 356.431L127.023 330.811C125.65 330.255 125.283 328.494 126.321 327.441L175.45 277.467C176.155 276.753 177.373 277.104 177.576 278.08L193.406 354.086C193.744 355.713 192.111 357.062 190.56 356.436L190.563 356.431Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M190.563 356.431L127.023 330.811C125.65 330.255 125.283 328.494 126.321 327.441L175.45 277.467C176.155 276.753 177.373 277.104 177.576 278.08L193.406 354.086C193.744 355.713 192.111 357.062 190.56 356.436L190.563 356.431Z"
                        fill="url(#paint2_linear_40_430)"
                    />
                    <path
                        d="M192.12 358.162C191.401 358.312 190.638 358.253 189.926 357.968L126.392 332.352C125.229 331.882 124.385 330.872 124.132 329.655C123.878 328.438 124.249 327.175 125.127 326.28L174.256 276.307C175.019 275.534 176.14 275.24 177.174 275.541C178.217 275.84 178.997 276.682 179.216 277.734L195.046 353.739C195.328 355.095 194.834 356.486 193.762 357.374C193.277 357.773 192.709 358.04 192.116 358.163L192.12 358.162ZM176.098 279.183L127.523 328.595L127.663 329.268L191.199 354.889C191.394 354.97 191.539 354.898 191.626 354.824C191.714 354.75 191.81 354.623 191.768 354.422L176.098 279.183Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M142.969 275.042L85.9077 286.926C85.004 287.114 84.1153 286.541 83.9277 285.641C83.7402 284.74 84.3218 283.861 85.23 283.672L142.292 271.788C143.195 271.6 144.084 272.173 144.272 273.073C144.459 273.974 143.878 274.853 142.969 275.042Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M146.987 294.334L89.9256 306.218C89.0218 306.406 88.1331 305.833 87.9456 304.933C87.7581 304.032 88.3397 303.153 89.2479 302.964L146.31 291.08C147.213 290.892 148.102 291.465 148.29 292.365C148.477 293.266 147.896 294.145 146.987 294.334Z"
                        fill="#5E79FF"
                    />
                    <path
                        d="M73.7608 379.59L111.526 310.147C114.918 303.906 123.15 302.191 128.756 306.558L191.103 355.151C192.499 356.242 191.974 358.453 190.23 358.817L76.0253 382.601C74.2808 382.965 72.916 381.142 73.7608 379.59Z"
                        fill="#DEE4FF"
                    />
                    <path
                        d="M73.7608 379.59L111.526 310.147C114.918 303.906 123.15 302.191 128.756 306.558L191.103 355.151C192.499 356.242 191.974 358.453 190.23 358.817L76.0253 382.601C74.2808 382.965 72.916 381.142 73.7608 379.59Z"
                        fill="url(#paint3_linear_40_430)"
                    />
                    <path
                        d="M190.568 360.444L76.3642 384.228C74.9119 384.531 73.4615 383.986 72.5789 382.812C71.6964 381.638 71.5823 380.099 72.2891 378.808L110.054 309.365C111.906 305.956 115.162 303.548 118.975 302.754C122.788 301.96 126.734 302.868 129.793 305.254L192.141 353.847C193.301 354.754 193.814 356.205 193.474 357.634C193.134 359.068 192.021 360.141 190.568 360.444ZM119.651 305.999C116.805 306.592 114.378 308.39 112.998 310.929L75.2334 380.376C75.1284 380.57 75.1933 380.724 75.265 380.82C75.3368 380.917 75.4716 381.024 75.6874 380.979L189.892 357.194C190.108 357.149 190.189 356.997 190.216 356.88C190.243 356.763 190.241 356.596 190.067 356.46L127.719 307.867C125.44 306.091 122.498 305.411 119.652 306.004L119.651 305.999Z"
                        fill="#5E79FF"
                    />
                    <g clip-path="url(#clip1_40_430)">
                        <path
                            d="M106.282 288.149C108.713 295.573 104.625 303.567 97.1542 306.002C89.6832 308.437 81.6551 304.394 79.2239 296.967C76.7927 289.541 80.8808 281.549 88.3518 279.114C95.8229 276.679 103.852 280.725 106.282 288.149Z"
                            fill="#F04E3E"
                        />
                        <path
                            d="M97.3765 306.681C89.5407 309.235 81.092 304.976 78.5429 297.189C75.9938 289.403 80.2944 280.991 88.1303 278.437C95.9661 275.884 104.414 280.14 106.963 287.927C109.512 295.713 105.212 304.125 97.3757 306.679L97.3765 306.681ZM88.5733 279.791C81.489 282.1 77.6002 289.706 79.9048 296.745C82.2095 303.785 89.8483 307.634 96.9327 305.325C104.017 303.016 107.906 295.41 105.601 288.371C103.297 281.331 95.6577 277.482 88.5733 279.791Z"
                            fill="#DEE4FF"
                        />
                        <path
                            d="M91.2038 295.184L88.2367 288.044L87.2683 285.086L92.7622 283.296L93.763 286.353L95.5862 293.756L91.2038 295.184ZM93.0024 301.739L91.432 296.942L96.3665 295.333L97.9369 300.13L93.0024 301.739Z"
                            fill="#DEE4FF"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_40_430"
                        x="124"
                        y="12"
                        width="281.716"
                        height="351.367"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feOffset dx="4" dy="4" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.266667 0 0 0 0 0.333333 0 0 0 0 0.666667 0 0 0 1 0"
                        />
                        <feBlend mode="normal" in="SourceGraphic" />
                    </filter>
                    <pattern
                        id="pattern0_40_430"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                    >
                        <image
                            id="image0_40_430"
                            width="3024"
                            height="4032"
                            href={portraitImg}
                            transform="matrix(0.000330688 0 0 0.000251078 0 -0.00617284)"
                        />
                    </pattern>
                    <pattern
                        id="pattern1_40_430"
                        patternContentUnits="objectBoundingBox"
                        width="4.56207"
                        height="5.21369"
                    >
                        <use xlinkHref="#image1_40_430" transform="scale(0.0114052 0.0130342)" />
                    </pattern>
                    <linearGradient
                        id="paint0_linear_40_430"
                        x1="106.484"
                        y1="242.778"
                        x2="133.127"
                        y2="370.709"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F04E3E" />
                        <stop offset="1" stop-color="#FED0D4" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_40_430"
                        x1="85.1135"
                        y1="296.244"
                        x2="101.62"
                        y2="375.502"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F04E3E" />
                        <stop offset="1" stop-color="#FED0D4" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_40_430"
                        x1="147.382"
                        y1="283.095"
                        x2="163.889"
                        y2="362.356"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F04E3E" />
                        <stop offset="1" stop-color="#FED0D4" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_40_430"
                        x1="119.312"
                        y1="304.374"
                        x2="133.127"
                        y2="370.709"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#F04E3E" />
                        <stop offset="1" stop-color="#FED0D4" />
                    </linearGradient>
                    <clipPath id="clip0_40_430">
                        <rect
                            width="129"
                            height="134"
                            fill="white"
                            transform="translate(43 254.302) rotate(-11.7645)"
                        />
                    </clipPath>
                    <clipPath id="clip1_40_430">
                        <rect
                            width="29.9273"
                            height="29.7516"
                            fill="white"
                            transform="matrix(0.950779 -0.309871 0.311126 0.950369 73.9202 283.069)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

BncFraudeHuang.propTypes = propTypes;
BncFraudeHuang.defaultProps = defaultProps;

export default BncFraudeHuang;
